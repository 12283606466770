<template>
  <div
    class="view-root view-content"
    style="padding: 30px 50px; box-sizing: border-box"
  >
    <div style="width: 100%; text-align: right">
      <div class="btnMessage" @click="clickShowSendMessage">Send a Message</div>
    </div>

    <div style="margin-top: 20px">
      <div
        v-for="(message, index) in dataList"
        class="message-item"
        :key="index"
      >
        <div style="width: 100%; text-align: right">
          <p class="message-time">
            {{ formatMessageTime(message.create_time) }}
          </p>
        </div>
        <p class="message-content" style="margin-top: 10px">
          {{ message.message_content }}
        </p>
        <div class="message-reply">
          <div
            v-if="message.reply_info"
            style="display: flex; flex-direction: row"
          >
            <p class="reply-title">Reply</p>
            <p class="reply-content">
              {{ message.reply_info.message_content }}
            </p>
          </div>
          <p v-else class="reply-content" style="padding-left: 0px">
            No reply yet
          </p>
        </div>
      </div>
    </div>

    <el-empty
      description="No Messages Yet"
      v-if="dataList.length === 0"
    ></el-empty>

    <el-dialog
      :visible.sync="showSendMessageDialog"
      title="Send Message"
      width="45%"
    >
      <div>
        <el-input
          v-model="text"
          type="textarea"
          :rows="10"
          placeholder="Please input"
        ></el-input>

        <div style="width: 100%; text-align: center; margin-top: 30px">
          <div class="btnMessage" @click="submitMessage">Send Message</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { findMyMessages, sendMessage } from "../../api/reff";
import { getUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";
import "../../assets/common/common.css";
import "../../assets/common/font.css";

export default {
  name: "index",
  data() {
    return {
      dataList: [],
      showSendMessageDialog: false,
      text: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.dataList = [];
      findMyMessages(getUserId()).then((res) => {
        this.dataList = res.data.data;
      });
    },
    formatMessageTime(time) {
      let arr = ['Jan.','Feb.','Mar.','Apr.','May.','Jun.','Jul.','Aug.','Sep.','Oct.','Nov.','Dec.']
      return arr[new Date(time * 1000).getMonth()]+' '+formatDate(new Date(time * 1000), "dd.yyyy");
    },
    submitMessage() {
      if (this.text == "") {
        this.$message.warning("Please input message");
        return;
      }
      sendMessage(getUserId(), this.text).then((res) => {
        this.$message.success("Submit message success~");
        this.showSendMessageDialog = false;
        this.fetchData();
      });
    },
    clickShowSendMessage() {
      this.text = "";
      this.showSendMessageDialog = true;
    },
  },
};
</script>
  
  <style scoped lang="less">
.btnMessage {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #ffc569;
  width: 145px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  font-family: "PingFang SC";
}

.message-item {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(255, 255, 255, 0.55);
  border-radius: 10px;
  padding: 18px 30px;
  box-sizing: border-box;
  margin-bottom: 18px;
}
.message-content {
  font-size: 15px;
  color: #666666;
}

.message-time {
  font-size: 15px;
  font-family: Century Gothic-Regular, Century Gothic;
  font-weight: 400;
  color: #949494;
}

.message-reply {
  margin-top: 18px;
  padding: 15px 18px;
  box-sizing: border-box;
  width: 100%;
  background: #f4f9ff;
  border-radius: 10px;
  display: flex;
  align-items: center;

  .reply-title {
    font-size: 18px;
    font-family: Century Gothic-Bold, Century Gothic;
    font-weight: bold;
    color: #579eff;
  }

  .reply-content {
    flex: 1;
    padding-left: 18px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: Century Gothic-Bold, Century Gothic;
    color: #666666;
  }
}
</style>
  